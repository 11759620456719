import { useEffect } from "react";

import ChatBubbleIcon from "@mui/icons-material/ChatBubble";

import Button from "@musicfy/components/Button";
import Divider from "@musicfy/components/Divider";
import { useSubscriptionContext } from "@musicfy/libs/SubscriptionProvider";
import { useAnalytics } from "@musicfy/utils/hooks";

import { Step } from "../constants";

interface ContactSupportProps {
  setStep: (step: Step) => void;
  onClose: () => void;
}

const ContactSupport = ({
  setStep,
  onClose,
}: ContactSupportProps): JSX.Element => {
  const { trackEvent } = useAnalytics();

  const { subscription } = useSubscriptionContext();

  useEffect(() => {
    trackEvent("Subscription Cancel Step Viewed", {
      plan: subscription?.plan,
      interval: subscription?.interval,
      step: "contact-support",
    });
  }, [trackEvent, subscription?.interval, subscription?.plan]);

  return (
    <div className="relative">
      <div className="bg-white/3 relative flex items-center overflow-hidden gap-3 text-white p-3">
        <div className="bg-white/2 backdrop-blur-xl top-0 left-0 w-full h-full absolute z-10" />
        <div className="absolute w-1/4 aspect-square top-6 left-0 rounded-full bg-pink-200 blur-2xl" />
        <div className="absolute w-1/4 aspect-square top-6 right-0 rounded-full bg-pink-400 blur-2xl" />
        <div className="absolute h-full aspect-square top-0 right-0 left-0 mx-auto rounded-full bg-purple blur-2xl" />
        <ChatBubbleIcon className="!text-20 relative z-20" />
        <div className="font-bold relative z-20">Let Us Help</div>
      </div>
      <div className="px-5 mt-6">
        <div className="font-bold text-22">Got a minute?</div>
        <div className="mt-3 text-gray-300 tracking-wide">
          We&apos;d love to see if there&apos;s a way we can provide what you
          need.
        </div>
      </div>
      <div className="px-5 mt-8">
        <Button
          className="mt-4"
          fullWidth
          onClick={() => {
            window.open("https://discord.gg/BHVtHCFmew", "_blank");
            onClose();
          }}
        >
          Something Wrong? Contact Us...
        </Button>
      </div>

      <Divider className="mb-4 mt-9" />
      <div className="px-3 pb-4 flex items-center justify-between">
        <Button
          variant="outlined"
          className="!outline-transparent !bg-transparent"
          style="secondary"
          onClick={() => {
            setStep(Step.CANCEL_REASON);
          }}
        >
          Back
        </Button>
        <Button
          variant="outlined"
          className="!outline-transparent"
          style="secondary"
          onClick={() => {
            setStep(Step.CONFIRM);
          }}
        >
          Decline Offer
        </Button>
      </div>
    </div>
  );
};

export default ContactSupport;
