/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { createContext, useCallback, useContext, useState } from "react";

import { type PaddleEventData } from "@paddle/paddle-js";
import { SubscriptionIntervals, type Subscription } from "@prisma/client";
import { useSession } from "next-auth/react";

import { type PaddleSubscription } from "@musicfy/types/paddle-server";
import { api } from "@musicfy/utils";
import { useAnalytics } from "@musicfy/utils/hooks";

export type IPaddleEventListener = (event: PaddleEventData) => void;

interface ISubscriptionContext {
  subscription: Subscription | null;
  paddleSubscription: PaddleSubscription | null;
  setSubscription: React.Dispatch<React.SetStateAction<Subscription | null>>;
  setPaddleSubscription: React.Dispatch<
    React.SetStateAction<PaddleSubscription | null>
  >;
  isLoading: boolean;
  isPaddleLoading: boolean;
  cancelSubscription: (reason?: string) => Promise<void>;
  renewSubscription: () => Promise<void>;
  pauseSubscription: (pauseDurationInMonths: number) => Promise<void>;
  resumeSubscription: () => Promise<void>;
  createStripePortalSession: (onSettled?: () => void) => void;
  refetchPaddleSubscription: () => void;
}

const SubscriptionContext = createContext<ISubscriptionContext | null>(null);

export function useSubscriptionContext() {
  const subscriptionContext = useContext(SubscriptionContext);

  if (!subscriptionContext) {
    throw new Error(
      "useSubscriptionContext must be used within a SubscriptionProvider"
    );
  }

  return subscriptionContext;
}

const SubscriptionProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { trackEvent } = useAnalytics();

  const { data } = useSession();
  const user = data?.user;

  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [paddleSubscription, setPaddleSubscription] =
    useState<PaddleSubscription | null>(null);

  const getSubscription = api.subscription.getSubscription.useQuery(
    { userId: user?.id || "" },
    {
      onSuccess: (data) => {
        setSubscription(data);
      },
      onError: (error) => {
        console.error(error);
      },
      enabled: !!user,
      refetchOnWindowFocus: false,
    }
  );

  const getPaddleSubscription =
    api.subscription.getSubscriptionFromPaddle.useQuery(
      { subscriptionId: subscription?.subscriptionId || "" },
      {
        onSuccess: (data) => {
          if (!data) {
            return;
          }
          setPaddleSubscription(data);
        },
        onError: (error) => {
          console.error(error);
        },
        enabled:
          !!subscription?.subscriptionId && subscription?.provider === "paddle",
        refetchOnWindowFocus: false,
      }
    );

  const cancelSubscriptionMutation =
    api.subscription.cancelSubscription.useMutation();

  const renewSubscriptionMutation =
    api.subscription.renewSubscription.useMutation();

  const createStripePortalSessionMutation =
    api.subscription.createStripePortalSession.useMutation();

  const pauseSubscriptionMutation =
    api.subscription.pauseSubscription.useMutation();

  const resumeSubscriptionMutation =
    api.subscription.resumeSubscription.useMutation();

  /** Functions to modify the subscription */
  const cancelSubscription = useCallback(
    async (reason = ""): Promise<void> => {
      if (!subscription) {
        return;
      }

      const updatedSubscription = await cancelSubscriptionMutation.mutateAsync({
        subscriptionId: subscription.subscriptionId,
        reason: reason,
      });

      if (!updatedSubscription) {
        return;
      }

      setSubscription(updatedSubscription);

      const mrr =
        subscription.interval === SubscriptionIntervals.month
          ? subscription.price / 100
          : subscription.price / 12 / 100;

      trackEvent("Subscription Canceled", {
        plan: subscription?.plan,
        interval: subscription?.interval,
        price: mrr,
        reason: reason,
      });
    },
    [cancelSubscriptionMutation, trackEvent, setSubscription, subscription]
  );

  const pauseSubscription = useCallback(
    async (pauseDurationInMonths: number) => {
      if (!subscription) {
        return;
      }

      try {
        const updatedSubscription = await pauseSubscriptionMutation.mutateAsync(
          {
            subscriptionId: subscription.subscriptionId,
            pauseDurationInMonths: pauseDurationInMonths,
          }
        );

        setSubscription(updatedSubscription);

        const mrr =
          subscription.interval === SubscriptionIntervals.month
            ? subscription.price / 100
            : subscription.price / 12 / 100;

        trackEvent("Subscription Retained", {
          plan: subscription?.plan,
          interval: subscription?.interval,
          price: mrr,
          reason: "Paused",
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    [pauseSubscriptionMutation, trackEvent, subscription]
  );

  const resumeSubscription = useCallback(async () => {
    if (!subscription) {
      return;
    }

    try {
      const updatedSubscription = await resumeSubscriptionMutation.mutateAsync({
        subscriptionId: subscription.subscriptionId,
      });

      setSubscription(updatedSubscription);

      const mrr =
        subscription.interval === SubscriptionIntervals.month
          ? subscription.price / 100
          : subscription.price / 12 / 100;

      /* Analytics */
      trackEvent("Subscription Resumed", {
        plan: subscription.plan,
        interval: subscription.interval,
        price: mrr,
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, [trackEvent, resumeSubscriptionMutation, subscription]);

  const renewSubscription = useCallback(async (): Promise<void> => {
    if (!subscription) {
      return;
    }

    const updatedSubscription = await renewSubscriptionMutation.mutateAsync({
      subscriptionId: subscription.subscriptionId,
    });

    if (!updatedSubscription) {
      return;
    }

    const mrr =
      subscription.interval === SubscriptionIntervals.month
        ? subscription.price / 100
        : subscription.price / 12 / 100;

    /* Analytics */
    trackEvent("Subscription Retained", {
      plan: subscription.plan,
      interval: subscription.interval,
      price: mrr,
      reason: "Renewal",
    });

    setSubscription(updatedSubscription);
  }, [trackEvent, renewSubscriptionMutation, subscription]);

  const createStripePortalSession = useCallback(
    (onSettled?: () => void) => {
      createStripePortalSessionMutation.mutate(
        {
          customerId: subscription?.customerId || "",
        },
        {
          onSuccess: (portalSession) => {
            window.location.href = portalSession.url;
          },
          onError: (error) => {
            console.error(error);
          },
          onSettled: () => {
            onSettled?.();
          },
        }
      );
    },
    [createStripePortalSessionMutation, subscription?.customerId]
  );

  const isSubscriptionLoading =
    getSubscription.isLoading && !subscription && !!user;

  const isPaddleSubscriptionLoading = getPaddleSubscription.isLoading;

  const subscriptionContextValue = {
    subscription: subscription,
    paddleSubscription: paddleSubscription,
    setPaddleSubscription: setPaddleSubscription,
    setSubscription: setSubscription,
    isLoading: isSubscriptionLoading,
    isPaddleLoading: isPaddleSubscriptionLoading,
    refetchPaddleSubscription: getPaddleSubscription.refetch,
    cancelSubscription: cancelSubscription,
    renewSubscription: renewSubscription,
    pauseSubscription: pauseSubscription,
    resumeSubscription: resumeSubscription,
    createStripePortalSession: createStripePortalSession,
  };

  return (
    <SubscriptionContext.Provider value={subscriptionContextValue}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionProvider;
