import { useCallback, useEffect, useState } from "react";

import FavoriteIcon from "@mui/icons-material/Favorite";
import { SubscriptionIntervals } from "@prisma/client";
import clsx from "clsx";

import Button from "@musicfy/components/Button";
import Divider from "@musicfy/components/Divider";
import { DiscountId } from "@musicfy/contants/Subscriptions";
import { useSubscriptionContext } from "@musicfy/libs/SubscriptionProvider";
import { api } from "@musicfy/utils";
import { useAnalytics } from "@musicfy/utils/hooks";

import { CANCELATION_REASONS, Reason, Step } from "../constants";

interface CancelReasonProps {
  setStep: (step: Step) => void;

  cancellationReason: Reason | string;
  setCancellationReason: (reason: Reason | string) => void;
}

const CancelReason = ({
  setStep,
  cancellationReason,
  setCancellationReason,
}: CancelReasonProps): JSX.Element => {
  const { trackEvent } = useAnalytics();

  const { subscription } = useSubscriptionContext();

  const [isCheckingEligibility, setIsCheckingEligibility] = useState(false);

  const { mutate: checkIsEligibleForDiscount } =
    api.subscription.checkIsSubscriptionEligibleForDiscount.useMutation({
      onMutate: () => {
        setIsCheckingEligibility(true);
      },
      onSettled: () => {
        setIsCheckingEligibility(false);
      },
      onError: () => {
        setStep(Step.CONFIRM);
      },
      onSuccess: (shouldShowDiscountStep) => {
        setStep(shouldShowDiscountStep ? Step.DISCOUNT : Step.CONFIRM);
      },
    });

  const handleNextStep = useCallback(() => {
    if (!cancellationReason || !subscription || isCheckingEligibility) {
      return;
    }

    if (cancellationReason === Reason.BUDGET) {
      const discountId =
        subscription?.interval === SubscriptionIntervals.month
          ? DiscountId.retain_monthly
          : DiscountId.retain_yearly;

      checkIsEligibleForDiscount({
        subscriptionId: subscription.subscriptionId,
        discountId: discountId,
      });
    } else if (cancellationReason === Reason.NO_LONGER_NEED) {
      setStep(Step.CONFIRM_PAUSE_SUBSCRIPTION);
    } else if (
      cancellationReason === Reason.TECHNICAL_ISSUES ||
      cancellationReason === Reason.MISSING_FEATURES
    ) {
      setStep(Step.CONTACT_SUPPORT);
    } else {
      setStep(Step.CONFIRM);
    }
  }, [
    cancellationReason,
    checkIsEligibleForDiscount,
    isCheckingEligibility,
    setStep,
    subscription,
  ]);

  useEffect(() => {
    trackEvent("Subscription Cancel Step Viewed", {
      plan: subscription?.plan,
      interval: subscription?.interval,
      step: "cancel-reason",
    });
  }, [
    trackEvent,
    subscription?.interval,
    subscription?.plan,
    cancellationReason,
  ]);

  return (
    <div className="relative lg:w-[400px]">
      <div className="bg-white/3 relative flex items-center overflow-hidden gap-3 text-white p-3">
        <div className="bg-white/2 backdrop-blur-xl top-0 left-0 w-full h-full absolute z-10" />
        <div className="absolute w-1/4 aspect-square top-6 left-0 rounded-full bg-pink-200 blur-2xl" />
        <div className="absolute w-1/4 aspect-square top-6 right-0 rounded-full bg-pink-400 blur-2xl" />
        <div className="absolute h-full aspect-square top-0 right-0 left-0 mx-auto rounded-full bg-purple blur-2xl" />
        <FavoriteIcon className="!text-20 relative z-20" />
        <div className="font-bold relative z-20">Your Feedback</div>
      </div>
      <div className="px-5 mt-6">
        <div className="font-bold text-22">We&apos;re sorry to see you go.</div>
        <div className="mt-3 text-gray-300 tracking-wide">
          Can you tell us why you&apos;re leaving?
        </div>
      </div>
      <div className="px-5 mt-8 flex flex-col gap-2">
        {CANCELATION_REASONS.map(({ value, letter }) => {
          const isSelected = cancellationReason === value;
          return (
            <div
              role="button"
              aria-label={value}
              key={letter}
              onClick={() => {
                setCancellationReason(value);
              }}
              className={clsx(
                "flex group items-center p-3 gap-3 rounded-4 border border-solid transition-all cursor-pointer",
                {
                  "border-white/50": isSelected,
                  "border-white/10 hover:border-white/20": !isSelected,
                }
              )}
            >
              <div
                className={clsx(
                  "font-bold rounded-4 transition-all aspect-square w-6 flex items-center justify-center",
                  {
                    "bg-purple": isSelected,
                    "bg-purple/60 group-hover:bg-purple": !isSelected,
                  }
                )}
              >
                {letter}
              </div>
              <div className="tracking-wide font-semibold">{value}</div>
            </div>
          );
        })}
      </div>

      <Divider className="mb-4 mt-9" />
      <div className="px-3 pb-4 flex items-center justify-between">
        <Button
          variant="outlined"
          className="!outline-transparent !bg-transparent"
          style="secondary"
          onClick={() => {
            setStep(Step.PAUSE_SUBSCRIPTION);
          }}
        >
          Back
        </Button>
        <Button
          variant="outlined"
          className="!outline-transparent"
          style="secondary"
          disabled={!cancellationReason || isCheckingEligibility}
          loading={isCheckingEligibility}
          onClick={handleNextStep}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default CancelReason;
