import { useCallback, useState } from "react";

import { SubscriptionPlans } from "@prisma/client";

import {
  PRICE_ID_TO_INTERVAL_MAP,
  PRICE_ID_TO_PLAN_MAP,
  ProductId,
  SubscriptionValueMap,
  type TPriceId,
} from "@musicfy/contants/Subscriptions";
import { api } from "@musicfy/utils";
import { useAnalytics } from "@musicfy/utils/hooks";

import { usePaddleContext } from "..";
import { useSubscriptionContext } from "../../SubscriptionProvider";
import { PaddleScreens } from "../types";

export const usePaddleUpgrade = () => {
  const { upgradePreviewContext, setActivePaddleScreen, origin, setOrigin } =
    usePaddleContext();
  const { subscription, setSubscription } = useSubscriptionContext();

  const { trackEvent } = useAnalytics();

  const [isProcessing, setIsProcessing] = useState(false);

  const {
    upgradePreviewData,
    setUpgradePreviewData,
    setIsUpgradeUpdating,
    isUpgradeUpdating,
  } = upgradePreviewContext;

  const getUpgradePreview = api.subscription.getUpgradePreview.useMutation({
    onSuccess: (data) => {
      if (!data) {
        setActivePaddleScreen(null);
        return;
      }

      setUpgradePreviewData(data);
    },
    onError: () => {
      setActivePaddleScreen(null);
    },
  });
  const changeSubscription = api.subscription.changeSubscription.useMutation({
    onMutate: () => {
      setIsProcessing(true);
    },
    onSettled: () => {
      setIsProcessing(false);
      setActivePaddleScreen(null);
    },
    onSuccess: (data) => {
      if (!data) {
        return;
      }
      const change =
        SubscriptionValueMap[data.plan] >
        SubscriptionValueMap[subscription?.plan || SubscriptionPlans.free]
          ? "Upgrade"
          : "Downgrade";
      trackEvent("Subscription Change", {
        previousPlan: subscription?.plan,
        previousInterval: subscription?.interval,
        newPlan: data.plan,
        newInterval: data.interval,
        change: change,
        origin: origin,
      });
      setSubscription(data);
    },
  });

  const startUpgrade = useCallback(
    (priceId: TPriceId) => {
      if (!subscription) {
        return;
      }
      setActivePaddleScreen(PaddleScreens.UPGRADE);

      // Check if we already have the price in the upgrade preview data
      if (
        !!upgradePreviewData?.items.find((item) => item.price.id === priceId)
      ) {
        return;
      } else if (!!upgradePreviewData) {
        setUpgradePreviewData(undefined);
      }

      getUpgradePreview.mutate({
        subscriptionId: subscription.subscriptionId,
        priceId: priceId,
      });

      /* Analytics */
      const product = PRICE_ID_TO_PLAN_MAP[priceId];
      const interval = PRICE_ID_TO_INTERVAL_MAP[priceId];

      trackEvent("Upgrade Started", {
        plan: product,
        interval: interval,
        origin: origin,
      });
    },
    [
      getUpgradePreview,
      origin,
      setActivePaddleScreen,
      setUpgradePreviewData,
      subscription,
      upgradePreviewData,
      trackEvent,
    ]
  );

  const updateNumberOfAdditionalVoices = useCallback(
    async (numberOfVoices: number) => {
      if (!subscription) {
        return;
      }

      const subscriptionPreviewItem = upgradePreviewData?.items.find((item) =>
        Object.values(ProductId).includes(item.price.product_id)
      );

      if (!subscriptionPreviewItem) {
        return;
      }

      setIsUpgradeUpdating(true);

      await getUpgradePreview.mutateAsync({
        subscriptionId: subscription.subscriptionId,
        priceId: subscriptionPreviewItem.price.id,
        numberOfAdditionalVoiceSlots: numberOfVoices,
      });

      setIsUpgradeUpdating(false);
    },
    [getUpgradePreview, setIsUpgradeUpdating, subscription, upgradePreviewData]
  );

  const closeUpgrade = useCallback(() => {
    if (isProcessing) {
      return;
    }
    setOrigin(undefined);
    setActivePaddleScreen(null);
  }, [isProcessing, setActivePaddleScreen, setOrigin]);

  const upgradeSubsription = useCallback(
    async (priceId: TPriceId, numberOfAdditionalVoices?: number) => {
      if (!subscription) {
        return;
      }

      await changeSubscription.mutateAsync({
        subscriptionId: subscription.subscriptionId,
        priceId: priceId,
        numberOfAdditionalVoices: numberOfAdditionalVoices,
      });
    },
    [changeSubscription, subscription]
  );

  return {
    startUpgrade,
    closeUpgrade,
    upgradeSubsription,
    updateNumberOfAdditionalVoices,
    isUpgradeUpdating,
  };
};
