import { useEffect, useState } from "react";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import InfoIcon from "@mui/icons-material/Info";
import { SubscriptionIntervals } from "@prisma/client";

import Button from "@musicfy/components/Button";
import Divider from "@musicfy/components/Divider";
import { DiscountId } from "@musicfy/contants/Subscriptions";
import { useSubscriptionContext } from "@musicfy/libs/SubscriptionProvider";
import { useToastContext } from "@musicfy/libs/ToastProvider";
import { api } from "@musicfy/utils";
import { useAnalytics } from "@musicfy/utils/hooks";

import { Step } from "../constants";

interface RetainStepProps {
  setStep: (step: Step) => void;
  onClose: () => void;
}

const Discount = ({ setStep, onClose }: RetainStepProps): JSX.Element => {
  const { subscription, setPaddleSubscription } = useSubscriptionContext();
  const { sendToastMessage } = useToastContext();

  const { trackEvent } = useAnalytics();

  const [isApplyingDiscount, setIsApplyingDiscount] = useState(false);

  const applyDiscount = api.subscription.applyDiscount.useMutation({
    onMutate: () => {
      setIsApplyingDiscount(true);
    },
    onSettled: () => {
      setIsApplyingDiscount(false);
      onClose();
    },
    onSuccess: (data) => {
      if (!subscription || !data) {
        return;
      }
      const mrr =
        subscription.interval === SubscriptionIntervals.month
          ? subscription.price / 100
          : subscription.price / 12 / 100;

      trackEvent("Subscription Retained", {
        plan: subscription?.plan,
        interval: subscription?.interval,
        price: mrr,
        reason: "Discount",
      });
      setPaddleSubscription(data);
      sendToastMessage("Discount applied successfully", {
        severity: "success",
      });
    },
  });

  useEffect(() => {
    if (subscription) {
      trackEvent("Subscription Cancel Step Viewed", {
        plan: subscription?.plan,
        interval: subscription?.interval,
        step: "discount",
      });
    }
  }, [trackEvent, subscription]);

  const discountText =
    subscription?.interval === SubscriptionIntervals.month
      ? "100% off your next renewal"
      : "50% off your next renewal";

  const discountId =
    subscription?.interval === SubscriptionIntervals.month
      ? DiscountId.retain_monthly
      : DiscountId.retain_yearly;

  return (
    <div className="relative">
      <div className="bg-white/3 relative flex items-center overflow-hidden gap-3 text-white p-3">
        <div className="bg-white/2 backdrop-blur-xl top-0 left-0 w-full h-full absolute z-10" />
        <div className="absolute w-1/4 aspect-square top-6 left-0 rounded-full bg-pink-200 blur-2xl" />
        <div className="absolute w-1/4 aspect-square top-6 right-0 rounded-full bg-pink-400 blur-2xl" />
        <div className="absolute h-full aspect-square top-0 right-0 left-0 mx-auto rounded-full bg-purple blur-2xl" />
        <InfoIcon className="!text-20 relative z-20" />
        <div className="font-bold relative z-20">Special Offer</div>
      </div>
      <div className="px-5 mt-6">
        <div className="font-bold text-22">The next one is on us!</div>
        <div className="mt-3 text-gray-300 tracking-wide">
          We understand budget constraints can be tough. We&apos;d like to offer
          you a special discount to help you stay with us.
        </div>
      </div>
      <div className="mt-6 mx-5 overflow-hidden relative rounded-8 p-4">
        <div className="bg-white/2 backdrop-blur-xl top-0 left-0 w-full h-full absolute z-10" />
        <div className="absolute w-1/4 aspect-square top-6 left-0 rounded-full bg-pink-200 blur-2xl" />
        <div className="absolute w-1/4 aspect-square top-6 right-0 rounded-full bg-pink-400 blur-2xl" />
        <div className="absolute h-full aspect-square top-0 right-0 left-0 mx-auto rounded-full bg-purple blur-2xl" />
        <div className="flex items-center gap-2 relative z-20">
          <AccessTimeIcon />
          <div className="font-semibold">Time-Limited Deal</div>
        </div>
        <div className="font-bold text-22 mt-2 relative z-20">
          {discountText}
        </div>
        <Button
          loading={isApplyingDiscount}
          className="mt-4 relative z-20"
          fullWidth
          onClick={() => {
            if (isApplyingDiscount) {
              return;
            }
            applyDiscount.mutate({
              discountId: discountId,
              subscriptionId: subscription?.subscriptionId || "",
            });
          }}
        >
          Accept This Offer
        </Button>
      </div>
      <Divider className="mb-4 mt-9" />
      <div className="px-3 pb-4 flex items-center justify-between">
        <Button
          variant="outlined"
          className="!outline-transparent !bg-transparent"
          style="secondary"
          onClick={() => {
            if (isApplyingDiscount) {
              return;
            }
            setStep(Step.CANCEL_REASON);
          }}
          disabled={isApplyingDiscount}
        >
          Back
        </Button>
        <Button
          variant="outlined"
          className="!outline-transparent"
          style="secondary"
          onClick={() => {
            if (isApplyingDiscount) {
              return;
            }
            setStep(Step.CONFIRM);
          }}
          disabled={isApplyingDiscount}
        >
          Decline Offer
        </Button>
      </div>
    </div>
  );
};

export default Discount;
