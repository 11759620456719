import { useCallback, useState } from "react";

import { sendGTMEvent } from "@next/third-parties/google";
import { SubscriptionPlans } from "@prisma/client";
import { usePostHog } from "posthog-js/react";

const useGTM = () => {
  const [userId, setUserId] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [subscriptionPlan, setSubscriptionPlan] =
    useState<SubscriptionPlans | null>(null);

  const identify = useCallback(
    (
      userId: string,
      email: string,
      subscriptionPlan?: SubscriptionPlans | undefined
    ) => {
      setUserId(userId);
      setEmail(email);
      setSubscriptionPlan(subscriptionPlan ?? SubscriptionPlans.free);

      sendGTMEvent({
        event: "identify",
        userId: userId,
        email: email,
        subscription: subscriptionPlan ?? SubscriptionPlans.free,
      });
    },
    []
  );

  const trackEvent = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: string, properties: Record<string, any>) => {
      sendGTMEvent({
        event: event,
        userId: userId,
        email: email,
        subscription: subscriptionPlan ?? SubscriptionPlans.free,
        ...properties,
      });
    },
    [email, subscriptionPlan, userId]
  );

  return {
    identify,
    trackEvent,
  };
};

const useAnalytics = () => {
  const posthog = usePostHog();
  const gtm = useGTM();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const trackEvent = (event: string, properties: Record<string, any>) => {
    posthog.capture(event, properties);
    gtm.trackEvent(event, properties);
  };

  const identify = useCallback(
    (
      userId: string,
      email: string,
      subscriptionPlan?: SubscriptionPlans | undefined
    ) => {
      () => {
        gtm.identify(userId, email, subscriptionPlan);
        posthog?.identify(
          userId,
          {
            subscription: subscriptionPlan ?? SubscriptionPlans.free,
          },
          {
            email: email,
          }
        );
      };
    },
    [posthog, gtm]
  );

  return {
    identify,
    trackEvent,
  };
};

export default useAnalytics;
